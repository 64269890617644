import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';
import { Usuario } from '../models/usuario.model';

export const OAUTH_CONFIG: AuthConfig = {
    issuer: `${environment.sso_url}`,
    redirectUri: `${environment.sso_redirect_uri}`,
    clientId: `${environment.sso_client_id}`,
    responseType: 'code',
    showDebugInformation: false,
    scope: 'openid profile',
    requireHttps: false,
};

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    user: Subject<Usuario> = new Subject<Usuario>();

    constructor(private oauthService: OAuthService) {}

    login(): void {
        this.oauthService.configure(OAUTH_CONFIG);
        this.oauthService.setStorage(localStorage);
        this.oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {
            this.oauthService.tryLogin().then(() => {
                if (!this.oauthService.hasValidAccessToken()) {
                    this.oauthService.initLoginFlow();
                } else {
                    this.oauthService.setupAutomaticSilentRefresh();
                    this.oauthService.loadUserProfile().then((up) => {
                        let posicao = up?.given_name?.indexOf(' ');
                        this.user.next({
                            //name: up?.given_name?.replace(/[\d]/g, '').trim(),
                            name: up?.given_name?.substring(posicao).trim(),
                            cpf: up?.given_name?.replace(/[^\d]/g, '').trim(),
                        });
                    });
                }
            });
        });
    }

    getUser(): Observable<Usuario> {
        return this.user;
    }

    logout() {
        this.oauthService.logOut();
    }
}
