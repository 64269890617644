import { Component, OnInit } from '@angular/core';
import { MenuDefinition } from './shared/components/menu/menu-definition';
import { AuthService } from './core/service/auth.service';
import { Observable } from 'rxjs';
import { Usuario } from './core/models/usuario.model';
import { ApiService } from './core/service/api.service';
import { Recursos } from './core/utils/recursos';
import { Api } from './core/service/models/api.model';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    title = 'SVG';
    user$: Observable<Usuario>;
    api: Api;
    usuario: Usuario;

    menuItens: MenuDefinition[] = [];

    constructor(
        private authService: AuthService,
        private apiService: ApiService
    ) {
        this.authService.login();
        this.authService.getUser().subscribe((usuario) => {
            this.usuario = usuario;
            this.buildMenu();
        });
    }

    handleLogout() {
        this.authService.logout();
    }

    buildMenu() {
        this.apiService.getApi().subscribe((api) => {
            this.api = api;
            this.menuItens.push({
                label: 'Dashboard',
                icon: 'dashboard',
                route: '/',
            });
            if (api._links.orcamentos) {
                this.menuItens.push(this.createMenuOrcamentos());
            }
            if (api._links.horaVoluntaria) {
                this.menuItens.push(this.createMenuHoraVoluntaria());
            }
            if (api._links.planejamentos) {
                this.menuItens.push(this.createMenuPlanejamentos());
            }
            if (api._links.distribuicoes) {
                this.menuItens.push(this.createMenuDistribuicao());
            }
            if (api._links.revogacoes){
                this.menuItens.push(this.createMenuRevogacao());
            }
            if (api._links.captacoes){
                 this.menuItens.push(this.createMenuCaptacao());
            }
            if (api._links.cotasDistribuidasSearch) {
                this.menuItens.push(this.createMenuConsultaCota());
            }
            if (api._links.captacaoPolicialSearch) {
                this.menuItens.push(this.createMenuVoluntarios());
            }
            if (api._links.gerarPagamentoSVG) {
                this.menuItens.push(this.createMenuPagamentos());
            }
        });
    }

    private createMenuOrcamentos() {
        let menuDef = {
            label: 'Orçamento',
            icon: 'account_balance',
            subitens: [
                {
                    label: 'Pesquisar',
                    icon: 'search',
                    route: '/orcamentos',
                },
            ],
        };
        if (
            this.api.recursos
                .map((r) => r.nome)
                .includes(Recursos.ALTERAR_ORCAMENTO_ANUAL)
        ) {
            menuDef.subitens.push({
                label: 'Cadastrar',
                icon: 'note_add',
                route: '/orcamentos/cadastrar',
            });
        }
        return menuDef;
    }

    private createMenuHoraVoluntaria() {
        let menuDef = {
            label: 'Valor das horas voluntário',
            icon: 'query_builder',
            subitens: [
                {
                    label: 'Pesquisar',
                    icon: 'search',
                    route: '/horas-voluntarias',
                },
            ],
        };
        if (
            this.api.recursos
                .map((r) => r.nome)
                .includes(Recursos.ALTERAR_HORA_VOLUNTARIA)
        ) {
            menuDef.subitens.push({
                label: 'Cadastrar',
                icon: 'note_add',
                route: '/horas-voluntarias/cadastrar',
            });
        }
        return menuDef;
    }

    private createMenuPlanejamentos() {
        let menuDef = {
            label: 'Planejamento Anual',
            icon: 'table_view',
            subitens: [
                {
                    label: 'Pesquisar',
                    icon: 'search',
                    route: '/planejamentos',
                },
            ],
        };
        if (
            this.api.recursos
                .map((r) => r.nome)
                .includes(Recursos.ALTERAR_PREVISAO_ORCAMENTARIA_MENSAL)
        ) {
            menuDef.subitens.push({
                label: 'Cadastrar',
                icon: 'note_add',
                route: '/planejamentos/cadastrar',
            });
        }
        return menuDef;
    }

    private createMenuDistribuicao() {
        let menuDef = {
            label: 'Distribuição de Cotas',
            icon: 'business',
            subitens: [
                {
                    label: 'Pesquisar',
                    icon: 'search',
                    route: '/distribuicoes',
                },
            ],
        };
        if (
            this.api.recursos
                .map((r) => r.nome)
                .includes(Recursos.ALTERAR_DISTRIBUICAO_COTAS)
        ) {
            menuDef.subitens.push({
                label: 'Cadastrar',
                icon: 'note_add',
                route: '/distribuicoes/cadastrar',
            });
        }
        return menuDef
    }
    private createMenuRevogacao() {
        let menuDef = {
            label: 'Revogação de Cotas',
            icon: 'settings_backup_restore',
            subitens: [
                {
                    label: 'Pesquisar',
                    icon: 'search',
                    route: '/revogacoes',
                },
            ],
        };
        if (
            this.api.recursos
                .map((r) => r.nome)
                .includes(Recursos.VISUALIZAR_CAPTACAO_GESTOR_GERAL) ||
            this.api.recursos
                .map((r) => r.nome)
                .includes(Recursos.VISUALIZAR_CAPTACAO_GESTOR_LOCAL) ||
            this.api.recursos
                .map((r) => r.nome)
                .includes(Recursos.VISUALIZAR_CAPTACAO_GESTOR_SETORIAL)
        ) {
            menuDef.subitens.push({
                label: 'Cadastrar',
                icon: 'note_add',
                route: '/revogacoes/cadastrar',
            });
        }
        return menuDef;
    }
    private createMenuCaptacao() {
        let menuDef = {
            label: 'Captação',
            icon: 'credit_card',
            subitens: [
                {
                    label: 'Pesquisar Ordem de Serviço',
                    icon: 'search',
                    route: '/captacoes/ordens-servicos',
                },
            ],
        };
        if (
            this.api.recursos
                .map((r) => r.nome)
                .includes(Recursos.VISUALIZAR_CAPTACAO_GESTOR_GERAL) ||
            this.api.recursos
                .map((r) => r.nome)
                .includes(Recursos.VISUALIZAR_CAPTACAO_GESTOR_LOCAL) ||
            this.api.recursos
                .map((r) => r.nome)
                .includes(Recursos.VISUALIZAR_CAPTACAO_GESTOR_SETORIAL)
        ) {
            menuDef.subitens.push({
                label: 'Pesquisar Captação',
                icon: 'search',
                route: '/captacoes',
            });
        }
        if (
            this.api.recursos
                .map((r) => r.nome)
                .includes(Recursos.ALTERAR_CAPTACAO_GESTOR_GERAL) ||
            this.api.recursos
                .map((r) => r.nome)
                .includes(Recursos.ALTERAR_CAPTACAO_GESTOR_LOCAL) ||
            this.api.recursos
                .map((r) => r.nome)
                .includes(Recursos.ALTERAR_CAPTACAO_GESTOR_SETORIAL)
        ) {
            menuDef.subitens.push({
                label: 'Cadastrar Captação',
                icon: 'note_add',
                route: '/captacoes/ordens-servicos',
            });
        }
        return menuDef;
    }

    private createMenuConsultaCota() {
        return {
            label: 'Consultar Cotas Distribuídas para Unidade',
            icon: 'find_in_page',
            subitens: [
                {
                    label: 'Pesquisar',
                    icon: 'search',
                    route: '/cotas-distribuidas',
                },
            ],
        };
    }

    private createMenuVoluntarios() {
        let menuDef = {
            label: 'Voluntário',
            icon: 'supervisor_account',
            subitens: [],
        };
        
            menuDef.subitens.push({
                label: 'Inscrição',
                icon: 'assignment_ind',
                route: '/voluntarios/inscricao',
            });
            menuDef.subitens.push({
                label: 'Meus Voluntários',
                icon: 'assignment_ind',
                route: '/voluntarios/meus-voluntarios',
            });
        
        if (
            this.api.recursos
                .map((r) => r.nome)
                .includes(Recursos.ACESSO_VISUALIZAR_SOLICITACAO_RETIRADA_SERVICO)
        ) {
            menuDef.subitens.push({
                label: 'Solicitações de Retirada SVG',
                icon: 'library_books',
                route: '/voluntarios/solicitacoes-retirada',
            });
        }
        /*if (
            this.api.recursos
                .map((r) => r.nome)
                .includes(Recursos.ACESSO_VISUALIZAR_ESCALA_SVG)
        ) {*/
            menuDef.subitens.push({
                label: 'Pesquisar Escala SVG',
                icon: 'search',
                route: '/voluntarios/escalas/pesquisar',
            });
        //}
        if (
          this.api.recursos
              .map((r) => r.nome)
              .includes(Recursos.ACESSO_ALTERAR_REGRA_VOLUNTARIO)
        ) {
            menuDef.subitens.push({
                label: 'Configurar Regras do Voluntário',
                icon: 'assignment',
                route: '/voluntarios/regras-voluntario/cadastrar',
            });
        }
        if (
            this.api.recursos
                .map((r) => r.nome)
                .includes(Recursos.ACESSO_VISUALIZAR_REGRA_VOLUNTARIO)
        ) {
            menuDef.subitens.push({
                label: 'Listar Regras do Voluntário',
                icon: 'assignment',
                route: '/voluntarios/regras-voluntario/pesquisar',
            });
        }

        return menuDef;
    }

    private createMenuPagamentos() {
        let menuDef = {
            label: 'Pagamento SVG',
            icon: 'payment',
            subitens: [],
        };
        if (
            this.api.recursos
                .map((r) => r.nome)
                .includes(Recursos.ACESSO_GERAR_PAGAMENTO_SVG)
        ) {
            menuDef.subitens.push({
                label: 'Gerar Arquivo',
                icon: 'insert_drive_file',
                route: '/pagamentos/gerar',
            });
        }
        
        return menuDef;
    }

    get sobreInfo(): String {
        return `<p>SVG - SERVIÇO VOLUNTÁRIO GRATIFICADO</p>
        <p>Versão API: ${this.api?.buildVersion}</p>`;
    }

    get contatoInfo(): String {
        return `Qualquer dúvida, entrar em contato com:<br /><br />
            <b>DITEL/SAU:</b> 85800 ou 31905800<br />`;
    }
}
