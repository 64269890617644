export class Recursos {
    static readonly ALTERAR_ORCAMENTO_ANUAL = 'ALTERAR_ORCAMENTO_ANUAL';
    static readonly VISUALIZAR_ORCAMENTO_ANUAL = 'VISUALIZAR_ORCAMENTO_ANUAL';
    static readonly ALTERAR_HORA_VOLUNTARIA = 'ALTERAR_HORA_VOLUNTARIA';
    static readonly VISUALIZAR_HORA_VOLUNTARIA = 'VISUALIZAR_HORA_VOLUNTARIA';
    static readonly ALTERAR_PREVISAO_ORCAMENTARIA_MENSAL =  'ALTERAR_PREVISAO_ORCAMENTARIA_MENSAL';
    static readonly VISUALIZAR_PREVISAO_ORCAMENTARIA_MENSAL =  'VISUALIZAR_PREVISAO_ORCAMENTARIA_MENSAL';
    static readonly VISUALIZAR_DISTRIBUICAO_COTAS = 'VISUALIZAR_DISTRIBUICAO_COTAS';
    static readonly ALTERAR_DISTRIBUICAO_COTAS = 'ALTERAR_DISTRIBUICAO_COTAS';
    static readonly ALTERAR_REVOGACAO_COTAS = 'ALTERAR_REVOGACAO_COTAS';
    static readonly VISUALIZAR_REVOGACAO_COTAS = 'VISUALIZAR_REVOGACAO_COTAS';
    static readonly VISUALIZAR_COTAS_DISTRIBUIDAS_GESTOR_GERAL = 'VISUALIZAR_COTAS_DISTRIBUIDAS_GESTOR_GERAL';
    static readonly VISUALIZAR_COTAS_DISTRIBUIDAS_GESTOR_SETORIAL =  'VISUALIZAR_COTAS_DISTRIBUIDAS_GESTOR_SETORIAL';
    static readonly VISUALIZAR_COTAS_DISTRIBUIDAS_GESTOR_LOCAL =  'VISUALIZAR_COTAS_DISTRIBUIDAS_GESTOR_LOCAL';
    static readonly VISUALIZAR_ORDEM_SERVICO_GESTOR_GERAL = 'VISUALIZAR_ORDEM_SERVICO_GESTOR_GERAL';
    static readonly VISUALIZAR_ORDEM_SERVICO_GESTOR_SETORIAL = 'VISUALIZAR_ORDEM_SERVICO_GESTOR_SETORIAL';
    static readonly VISUALIZAR_ORDEM_SERVICO_GESTOR_LOCAL = 'VISUALIZAR_ORDEM_SERVICO_GESTOR_LOCAL';
    static readonly ALTERAR_CAPTACAO_GESTOR_GERAL = 'ALTERAR_CAPTACAO_GESTOR_GERAL';
    static readonly ALTERAR_CAPTACAO_GESTOR_SETORIAL = 'ALTERAR_CAPTACAO_GESTOR_SETORIAL';
    static readonly ALTERAR_CAPTACAO_GESTOR_LOCAL = 'ALTERAR_CAPTACAO_GESTOR_LOCAL';
    static readonly VISUALIZAR_CAPTACAO_GESTOR_GERAL = 'VISUALIZAR_CAPTACAO_GESTOR_GERAL';
    static readonly VISUALIZAR_CAPTACAO_GESTOR_SETORIAL = 'VISUALIZAR_CAPTACAO_GESTOR_SETORIAL';
    static readonly VISUALIZAR_CAPTACAO_GESTOR_LOCAL = 'VISUALIZAR_CAPTACAO_GESTOR_LOCAL';
    static readonly REALIZAR_INSCRICAO_VOLUNTARIO = 'REALIZAR_INSCRICAO_VOLUNTARIO';
    static readonly ACESSO_VISUALIZAR_SOLICITACAO_RETIRADA_SERVICO = 'VISUALIZAR_SOLICITACAO_RETIRADA_SERVICO';
    static readonly ACESSO_VISUALIZAR_REGRA_VOLUNTARIO = 'VISUALIZAR_REGRA_VOLUNTARIO';
    static readonly ACESSO_ALTERAR_REGRA_VOLUNTARIO = "ALTERAR_REGRA_VOLUNTARIO";
    static readonly ACESSO_GERAR_ESCALA_SVG = "GERAR_ESCALA_SVG";
    static readonly ACESSO_VISUALIZAR_ESCALA_SVG = 'VISUALIZAR_ESCALA_SVG';
    static readonly ACESSO_GERAR_PAGAMENTO_SVG = 'GERAR_PAGAMENTO_SVG';
}
