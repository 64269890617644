import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Recursos } from './core/utils/recursos';
import { RecursoGuard } from './core/service/recurso-guard.service';

const routes: Routes = [
    {
        path: 'orcamentos',
        canActivate: [RecursoGuard],
        data: {
            recursos: [
                Recursos.VISUALIZAR_ORCAMENTO_ANUAL,
                Recursos.ALTERAR_ORCAMENTO_ANUAL,
            ],
        },
        loadChildren: () =>
            import('./orcamentos/orcamentos.module').then(
                (m) => m.OrcamentosModule
            ),
    },
    {
        path: 'horas-voluntarias',
        canActivate: [RecursoGuard],
        data: {
            recursos: [
                Recursos.VISUALIZAR_HORA_VOLUNTARIA,
                Recursos.ALTERAR_HORA_VOLUNTARIA,
            ],
        },
        loadChildren: () =>
            import('./horas-voluntarias/hora-voluntaria.module').then(
                (m) => m.HoraVoluntariaModule
            ),
    },
    {
        path: 'planejamentos',
        canActivate: [RecursoGuard],
        data: {
            recursos: [
                Recursos.VISUALIZAR_PREVISAO_ORCAMENTARIA_MENSAL,
                Recursos.ALTERAR_PREVISAO_ORCAMENTARIA_MENSAL,
            ],
        },
        loadChildren: () =>
            import('./planejamentos/planejamentos.module').then(
                (m) => m.PlanejamentosModule
            ),
    },
    {
        canActivate: [RecursoGuard],
        data: {
            recursos: [
                Recursos.VISUALIZAR_DISTRIBUICAO_COTAS,
                Recursos.ALTERAR_DISTRIBUICAO_COTAS,
            ],
        },
        path: 'distribuicoes',
        loadChildren: () =>
            import('./distribuicoes/distribuicoes.module').then(
                (m) => m.DistribuicoesModule
            ),
    },
    {
        canActivate: [RecursoGuard],
        data: {
            recursos: [
                Recursos.VISUALIZAR_REVOGACAO_COTAS,
                Recursos.ALTERAR_REVOGACAO_COTAS,
            ],
        },
        path: 'revogacoes',
        loadChildren: () =>
            import('./revogacoes/revogacoes.module').then(
                (m) => m.RevogacoesModule
            ),
    },
    {   canActivate: [RecursoGuard],
        data: {
            recursos: [
                Recursos.VISUALIZAR_CAPTACAO_GESTOR_GERAL,
                Recursos.VISUALIZAR_CAPTACAO_GESTOR_LOCAL,
                Recursos.VISUALIZAR_CAPTACAO_GESTOR_SETORIAL,
                Recursos.ALTERAR_CAPTACAO_GESTOR_LOCAL,
                Recursos.ALTERAR_CAPTACAO_GESTOR_GERAL,
                Recursos.ALTERAR_CAPTACAO_GESTOR_SETORIAL,
                Recursos.VISUALIZAR_ORDEM_SERVICO_GESTOR_GERAL,
                Recursos.VISUALIZAR_ORDEM_SERVICO_GESTOR_LOCAL,
                Recursos.VISUALIZAR_ORDEM_SERVICO_GESTOR_SETORIAL
            ],
        },
        path: 'captacoes',
        loadChildren: () =>
            import('./captacoes/captacoes.module').then(
                (m) => m.CaptacoesModule
            ),
    },
    {
      path: 'cotas-distribuidas',
      canActivate: [RecursoGuard],
      data: {
        recursos: [
            Recursos.VISUALIZAR_COTAS_DISTRIBUIDAS_GESTOR_GERAL,
            Recursos.VISUALIZAR_COTAS_DISTRIBUIDAS_GESTOR_LOCAL,
            Recursos.VISUALIZAR_COTAS_DISTRIBUIDAS_GESTOR_SETORIAL,
        ],
    },
      loadChildren: () =>
          import('./cotas-distribuidas/cota-distribuida.module').then(
              (m) => m.CotaDistribuidaModule
          ),
    },
    {
      path: 'voluntarios',

      loadChildren: () =>
          import('./voluntarios/voluntarios.module').then(
              (m) => m.VoluntariosModule
          ),
    },
    {
        path: 'pagamentos',
        canActivate: [RecursoGuard],
        data: {
            recursos: [
                Recursos.ACESSO_GERAR_PAGAMENTO_SVG
            ],
        },
        loadChildren: () =>
            import('./pagamentos/pagamentos.module').then(
                (m) => m.PagamentosModule
            ),
      },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [RecursoGuard],
})
export class AppRoutingModule {}
