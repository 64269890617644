import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { OrcamentoAnualFilter } from '../models/orcamento-filter.model';
import { PagedCollectionResponse } from './models/collection-response.model';
import { OrcamentoAnual } from '../models/orcamento-anual.model';

@Injectable({
    providedIn: 'root',
})
export class OrcamentoAnualService {
    constructor(private api: ApiService, private http: HttpClient) {}

    search(
        sort: string,
        order: string,
        page: number,
        size: number,
        filter: OrcamentoAnualFilter
    ): Observable<PagedCollectionResponse<OrcamentoAnual>> {
        return this.api.getApi().pipe(
            switchMap((api) =>
                this.http.post<PagedCollectionResponse<OrcamentoAnual>>(
                    api._links.orcamentosAnuaisSearch.href,
                    filter,
                    {
                        params: new HttpParams()
                            .append('sort', `${sort},${order}`)
                            .append('page', String(page))
                            .append('size', String(size)),
                    }
                )
            )
        );
    }

    delete(value: OrcamentoAnual) {
        return this.http.delete(value._links.self.href);
    }

    findByAno(ano: string): Observable<OrcamentoAnual> {
        return this.api.getApi().pipe(
            switchMap((api) => {
                if (api._links.orcamentos.templated) {
                    return this.http.get<OrcamentoAnual>(
                        api._links.orcamentosAnuaisByAno.href.replace(
                            '{ano}',
                            ano
                        )
                    );
                } else {
                    console.error('O endereço não está parametrizado pelo ANO');
                }
            })
        );
    }

    findAll(): Observable<any> {
        return this.api.getApi().pipe(
            switchMap((api) => {
                return this.http.get(api._links.orcamentosAnuais.href);
            })
        );
    }

    findAllWithOrcamentosPublicados(): Observable<any> {
        return this.api.getApi().pipe(
            switchMap((api) => {
                let data = {'isWithOrcamentosPublicados': 'true'};
                return this.http.get(api._links.orcamentosAnuais.href, {params: data});
            })
        );
    }

    findAllWithPlanejamentos(): Observable<any> {
      return this.api.getApi().pipe(
          switchMap((api) => {
              let data = {'isWithPlanejamento': 'true'};
              return this.http.get(api._links.orcamentosAnuais.href, {params: data});
          })
      );
  }
}
